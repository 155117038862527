import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StatusLabel = makeShortcode("StatusLabel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "hds-accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`HDS Accessibility`}<a parentName="h1" {...{
        "href": "#hds-accessibility",
        "aria-label": "hds accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  The Helsinki Design System is designed and built to be accessible for all, regardless of ability or situation.
    </LeadParagraph>
    <h2 {...{
      "id": "accessibility-in-the-city-of-helsinki-digital-services",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility in the City of Helsinki digital services`}<a parentName="h2" {...{
        "href": "#accessibility-in-the-city-of-helsinki-digital-services",
        "aria-label": "accessibility in the city of helsinki digital services permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>
  The basis of HDS accessibility is
  <ExternalLink href="https://eur-lex.europa.eu/eli/dir/2016/2102/oj" mdxType="ExternalLink">
   an EU directive on web accessibility 
  </ExternalLink>
  which mandates that all new public sector websites published by 23.09.2019 have to comply with
  <ExternalLink href="https://www.w3.org/TR/WCAG20/" mdxType="ExternalLink">WCAG 2.0 Standard at AA Level</ExternalLink>. On 23.09.2020 all
  websites, new and old, have to meet this criterion. HDS aims to make the life of projects easier by providing
  components, patterns, and guidelines that meet these requirements.
    </p>
    <h2 {...{
      "id": "standards-behind-hds-development",
      "style": {
        "position": "relative"
      }
    }}>{`Standards behind HDS development`}<a parentName="h2" {...{
        "href": "#standards-behind-hds-development",
        "aria-label": "standards behind hds development permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Following the EU directive, HDS aims to fulfill `}<ExternalLink href="https://www.w3.org/TR/WCAG20/" mdxType="ExternalLink">{`WCAG 2.0 Standard at AA Level`}</ExternalLink>{`.`}</p>
    <p>{`However, HDS follows the newer `}<ExternalLink href="https://www.w3.org/TR/WCAG21/" mdxType="ExternalLink">{`WCAG 2.1 Standard`}</ExternalLink>{`. The publication of WCAG 2.1 does not deprecate or supersede WCAG 2.0 and W3C advice to use it. Even though the EU directive requires fulfilling the standard at the AA level, HDS also implements many requirements from the AAA level.`}</p>
    <p>{`In addition to WCAG 2.1 and 2.0, HDS also refers to the following guidelines:`}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://www.w3.org/TR/WCAG22/" mdxType="ExternalLink">WCAG 2.2 Standard (draft) at AA level</ExternalLink>. This is done
for future-proofing reasons even though the requirements are not part of the official WCAG requirements yet.
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.hel.fi/static/liitteet/kanslia/TPR/opas_saavutettavaan_sisaltoon_EN.pdf" mdxType="ExternalLink">
  The City of Helsinki content accessibility guidelines
        </ExternalLink>
. They are heavily based on WCAG requirements but still include good extra practices.
      </li>
      <li parentName="ul">
        <ExternalLink href="https://dev.hel.fi/accessibility" mdxType="ExternalLink">
  Develop with Helsinki - accessibility guidelines for developers
        </ExternalLink>
.
      </li>
    </ul>
    <h2 {...{
      "id": "hds-accessibility-process",
      "style": {
        "position": "relative"
      }
    }}>{`HDS accessibility process`}<a parentName="h2" {...{
        "href": "#hds-accessibility-process",
        "aria-label": "hds accessibility process permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p><strong parentName="p">{`New components and patterns are not released before their accessibility has been audited by a third-party auditor. This includes components marked as Drafts.`}</strong>{` If components or patterns are released without an audit, they will be indicated in the documentation. This may happen if the component is quickly needed by some project or if it takes a considerably long time to make the component accessible.`}</p>
    <p>{`Component accessibility is ensured in all stages of HDS design and development. The process is following:`}</p>
    <ol>
      <li parentName="ol">{`During the design phase, auditors will comment on the designs from a visual perspective. They will also give pointers on which parts of the design may cause challenges for accessibility during implementation.`}</li>
      <li parentName="ol">{`During the implementation specification phase, auditors will comment on the specifications from the accessibility point of view. Specifications will include things like keyboard navigation, usage of aria features, etc.`}</li>
      <li parentName="ol">{`During implementation, auditors will actively test the component and suggest changes if needed.`}</li>
      <li parentName="ol">{`During the documentation phase, auditors will read through the documentation and suggest changes and clarifications if needed.`}</li>
      <li parentName="ol">{`After all phases have been accepted by the auditors, the component will be marked with the following status label in the HDS documentation: `}<StatusLabel type="success" mdxType="StatusLabel">{`Accessible`}</StatusLabel></li>
    </ol>
    <h2 {...{
      "id": "accessibility-in-projects-using-hds",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility in projects using HDS`}<a parentName="h2" {...{
        "href": "#accessibility-in-projects-using-hds",
        "aria-label": "accessibility in projects using hds permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`HDS promises to ensure component and pattern level accessibility. This means that a project that uses HDS components and patterns and follows HDS guidelines can trust that these parts of the service are accessible. However, if the components are used in other ways or use cases than specified in the HDS documentation, HDS cannot promise accessibility. `}<strong parentName="p">{`It is good to keep in mind that using HDS does not alone make the service accessible!`}</strong></p>
    <p>{`Here are some things projects should take into account when designing accessible services using HDS:`}</p>
    <ul>
      <li parentName="ul">{`Get at least a basic level familiarity of `}<ExternalLink href="https://www.w3.org/TR/WCAG20/" mdxType="ExternalLink">{`WCAG 2.0 Standard at AA Level`}</ExternalLink>{`. Your service is required to follow WCAG guidelines at A and AA levels.`}</li>
      <li parentName="ul">{`HDS components allow a lot of customisation. When customising component colours, ensure accessibility. For more information, refer to `}<InternalLink size="M" href="/foundation/design-tokens/colour#customising-colours" mdxType="InternalLink">{`HDS Colours - Customising colours documentation`}</InternalLink>{`.`}</li>
      <li parentName="ul">{`When designing components that are not part of the HDS, you are required to ensure accessibility by yourself. It may help to refer to HDS components for good accessibility design and implementation practices. You may also contact the HDS team if you need assistance.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      